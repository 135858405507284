<template>
  <template v-if="isMobile">
    <!-- begin::kb-header -->
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
          스타런 홈페이지 개선의견 경청 이벤트
        </div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" @click="goBack" class="util-actions util-actions-back">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>
    </header>
    <!-- end::kb-header -->

    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-renewal" style="margin-bottom:100px;">

      <!-- main-content -->
      <div class="main-content main-component">

        <div class="renewal-container">
          <div class="view-contents">
            <div class="renewal-header">
              <span class="subtitle">EVENT</span>
              <h2 class="title"> 스타런 홈페이지 개선의견 경청 이벤트</h2>
              <p class="meta">2022.12.13(화) - 12.30(금) </p>
            </div>
            <div class="renewal-contents">
              <!-- content-section:이벤트 안내 -->
              <article class="content-section section-notice">
                <header class="section-header header-divider">
                  <h3 class="title">이벤트 안내</h3>
                </header>
                <p class="text">
                  홈페이지를 둘러보고 <strong>①오류</strong> 또는 <strong>②개선사항</strong>을 알려주세요! 오류 또는 개선사항을 찾지 못했다면, <strong>③가장 마음에 드는 기능과 그 이유를 남겨주세요!</strong>
                </p>
                <div class="text-bottom">
                  <p class="text text-muted">의견은 여러 번 제출할 수 있으나 참여 횟수는 1회로 산정</p>
                </div>
              </article>
              <!-- content-section:의견 작성 -->
              <article class="content-section section-notice">
                <header class="section-header header-divider">
                  <h3 class="title">의견 작성</h3>
                </header>
                <div class="kb-form-fields">
                  <div class="kb-form-item">
                    <div class="kb-form-row">
<!--                      <label class="kb-form-label">-->
<!--                        <span class="kb-form-label-text">의견내용</span>-->
<!--                        <span class="kb-form-byte-text">0 / 300</span>-->
<!--                      </label>-->
                    </div>
                    <div class="kb-form-row">
                      <textarea v-model="pearlItem.ntcCn" name="" id="" placeholder="이벤트에 관련하여 의견을 남겨주세요." class="kb-form-control"></textarea>
                    </div>
                  </div>
                </div>
              </article>
              <!-- content-section:파일 첨부 -->
              <article class="content-section">
                <header class="section-header header-divider">
                  <h3 class="title">파일 첨부</h3>
                </header>
                <div class="kb-form-fields">
                  <div class="kb-form-field field-first">
                    <div class="kb-form-row">
                      <label class="kb-form-label"><span class="kb-form-label-text">첨부파일</span></label>
                    </div>
                    <div class="kb-form-row">
                      <div class="file-text">
                        <p class="text text-muted" v-if="fileName != null">{{fileName}}</p>
                        <p class="text text-muted" v-else>첨부파일을 등록해주세요.</p>

                        <div class="close">
<!--                          <span class="close-text">1.0MB </span>-->
                          <button v-if="fileName != null" type="button" @click="removefile">
                            <i v-if="fileName !== null" class="icon-del"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-row row-buttons">
                      <label class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for="inp_file2">
                        <span class="text">첨부파일 등록</span>
                        <input type="file" ref="uploadFiles" class="kb-form-file-input" id="inp_file2" name="inp_file" @change="addFile"/>
                      </label>
                    </div>
                    <div class="kb-form-row">
                      <span class="text font-body12 text-muted">
                        필요 시, 화면 캡처 후 파일첨부 (파일형식 JPG,PNG,PDF 등 첨부가능) <br> 클라우드 PC를 사용하는 본부부서의 경우, 스크린샷을 "바탕화면"에 저장 후 등록 or 암호화 해제 후 등록)
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="clickSave">의견제출</button></div>
      </div>
      <!-- //main-content -->
    </main>
    <!-- end::kb-main -->
  </template>



  <template v-else>
    <main class="kb-main" id="kb-myclass-notice">
      <lxp-main-header :showBreadcrumb="true" :showPearl="true" :show-custom-title="true" title="스타런 홈페이지 개선의견 경청 이벤트">
      </lxp-main-header>
      <!-- main-content -->
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="renewal-container">

          <form action="">

            <!-- content-section: 공지사항 -->`
            <article class="content-section">
              <header class="section-header">
                <h4 class="title">이벤트 안내</h4>
              </header>
              <div class="segment-box">
                <p class="text-lg"><strong>KB 스타런을 사용해보고</strong>
                  ①오류 <strong>또는</strong> ② 개선사항<strong>또는</strong>③ 가장 마음에 드는 기능과 그 이유<strong> 를 남겨주세요</strong>
                </p>
              </div>
              <div class="segment-box-bottom">
                <p class="text text-muted text-icon">의견 중복 제출 가능하나, 참여횟수는 1회로 산정됩니다.</p>
              </div>
            </article>

            <!-- content-section: 의견 작성 -->
            <article class="content-section section-divider">
              <header class="section-header">
                <h4 class="title">의견 작성</h4>
              </header>
              <div class="segment-box">
                <textarea v-model="pearlItem.ntcCn" class="kb-form-control" placeholder="이벤트에 관련하여 의견을 남겨주세요."></textarea>
                <div class="textarea-bottom divider">
<!--                  <div class="bottom-bolumn">-->
<!--                    <span class="counter">{{textLength}}</span>-->
<!--                  </div>-->
                  <div class="bottom-bolumn"></div>
                </div>
              </div>
            </article>

            <!-- content-section: 파일 첨부 -->
            <article class="content-section">
              <header class="section-header">
                <h4 class="title">파일 첨부</h4>
              </header>
              <div class="kb-form-fields kb-form-fields-v2 v2-bottom-none">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-filelist">
                    <div class="file">
                      <span class="file-name-placeholder kb-form-text">
                        {{fileName}} <button v-if="fileName != null" type="button" @click="removefile" style="margin-left: 10px;"><i class="icon-x"></i></button>
                      </span>
                    </div>
                  </div>
                  <div class="kb-form-column column-last">
                    <div class="file-form">
                      <input ref="uploadFiles" type="file" id="inpFile" name="inpFile" class="file-kb-form-input" @change="addFile" />
                      <label for="inpFile" class="kb-btn kb-btn-secondary"><span class="text">첨부파일 등록</span></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields-bottom">
                <div class="text-notice">
                  <p class="text">필요 시, 화면 캡처 후 파일첨부 (파일형식 JPG,PNG,PDF 등 첨부가능)</p>
                  <p class="text">클라우드 PC를 사용하는 본부부서의 경우, 스크린샷을 "바탕화면"에 저장 후 등록 or 암호화 해제 후 등록)</p>
                </div>
              </div>
            </article>

            <div class="page-buttons">
              <button @click="clickSave" type="button" class="kb-btn kb-btn-primary kb-btn-xl"><span class="text">의견제출</span></button>
            </div>
          </form>
        </div>
      </div>
      <!-- //main-content -->
    </main>
  </template>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';

import {checkExtension} from '@/assets/js/file-util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {isSuccess, isUploadSuccess} from '@/assets/js/util';
import {insertPearlFileAtch} from '@/assets/js/modules/hrd/hrd-common';
import {ACT_INSERT_PEARL} from '@/store/modules/trnct/trnct';
import navigationUtils from '@/assets/js/navigationUtils';
import {goBack} from '@/assets/js/modules/common/common';
import {serverUploadError} from '@/assets/js/modules/error/error-message';

export default {
  name: 'Pearl',
  components: {LxpMainHeader},
  setup(){
    const store = useStore(), {showLoading, hideLoading} = useAlert();
    const uploadFiles = ref(null);
    const {showMessage} = useAlert();
    const files = ref({files: [], removed: [], binaries: []});
    const isLoading = ref(false);
    const fileName = ref(null);

    /*const search = reactive({
      ntcTitle:'',
      ntcCateCdDcd: ''
    });*/

    const pearlItem = ref({
      divCdDcd : '2013010',
      ntcTitle : '스타런 홈페이지 개선의견 경청 이벤트',
      ntcCn : '',
      inqCnt : 0,
      topFixYn : 'N',
      expsrYn : 'N',
      ntcFileAtchYn : 'N',//파일이 있으면 Y 없으면 N
      stt: '00'
    })


    const textLength = computed(() => {
      if(pearlItem.value.ntcCn){
        return pearlItem.value.ntcCn.length;
      }
      return 0;
    });

    onMounted(() => {

    });

    const addFile = () => {
      let checkType = ['jpg' ,'jpeg' ,'png' ,'webp' ,'bmp','pdf'];
      if (uploadFiles.value && uploadFiles.value.files && uploadFiles.value.files.length > 0) {
        if(checkExtension(uploadFiles.value.files, checkType)){
          showMessage(`확장자가 ${checkType.join(', ')} 인 파일만 등록 가능합니다.`);
          return;
        }
        if((files.value.files.length + uploadFiles.value.files.length) > 1) {
          showMessage(`첨부파일은 최대 1개까지 등록 가능합니다.`);
          uploadFiles.value.value = null;
          uploadFiles.value.files = null;
          return;
        }

        for(let i=0; i < uploadFiles.value.files.length; i++){
          uploadFiles.value.files[i].fileNm = uploadFiles.value.files[i].name
          uploadFiles.value.files[i].fileSz = uploadFiles.value.files[i].size
          files.value.files.push(uploadFiles.value.files[i]);
          fileName.value = uploadFiles.value.files[i].name;
        }
      }
    }

    const clickSave = () => {
      if(isLoading.value) return;
      showLoading();
      isLoading.value = true;

      console.log('click Save')

      if(pearlItem.value.ntcCn === ''){
        showMessage('내용을 입력하세요');
        isLoading.value = false;
        return false;
      }

      store.dispatch(`trnct/${ACT_INSERT_PEARL}`, pearlItem.value)
      .then(async (res) => {
        if (isSuccess(res)) {
          if(files.value.files.length > 0){
            pearlItem.value.ntcFileAtchYn = 'Y';
            let fileRes = await insertPearlFileAtch({trnctNtcSn: res.trnctNtcSn}, files.value.files);
            if (isUploadSuccess(fileRes)) {
              // isLoading.value = false;
              showMessage({
                text: "의견제출이 완료되었습니다.",
                callback:() => {
                  clearParam();
                }
              })
            } else {
              showMessage(serverUploadError);
            }
          }else{
            showMessage({
              text: " 의견제출이 완료되었습니다.",
              callback:() => {
                clearParam();
              }
            })
          }
        }else{
          showMessage('등록 실패했습니다.')
        }
        hideLoading();
        isLoading.value = false;
      }).catch((err) => {
        showMessage("QnA 등록 실패<br> 관리자에게 문의해주세요");
        console.error(err)
        hideLoading();
        isLoading.value = false;
      });
    }

    const clearParam = () => {
      pearlItem.value = {
        divCdDcd : '2013010',
        ntcTitle : '스타런 홈페이지 개선의견 경청 이벤트',
        ntcCn : '',
        inqCnt : 0,
        topFixYn : 'N',
        expsrYn : 'N',
        ntcFileAtchYn : 'N',//파일이 있으면 Y 없으면 N
        stt: '00'
      }

      fileName.value = null;
      files.value.files = [];
      uploadFiles.value = null;
    }

    const removefile = () => {
      fileName.value = null;
      files.value.files = [];
      uploadFiles.value = null;
    }

    return{
      addFile,
      uploadFiles,
      pearlItem,
      clickSave,
      textLength,
      clearParam,
      files,
      fileName,
      removefile,
      goBack,
      isMobile:navigationUtils.any()
    }

  }
}
</script>
